<template>
  <v-dialog id="dialog" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        text 
        class="primary--text"
        :disabled="project.applicationCount >= project.quota.applications"
      >
        <v-icon class="mr-1">add_box</v-icon>
        Create Application
        {{ project.applicationCount >= project.quota.applications }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Create New Application</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="createApplication()">
          <v-text-field required label="Name" v-model="application.name" />
          <v-text-field
            required
            label="Container image"
            v-model="application.image"
            />
          <v-text-field
            label="Command"
            v-model="application.command"
            >
          </v-text-field>
          <!-- Entrypoint -->
          <v-text-field
            label="Entrypoint"
            v-model="application.entrypoint"
             />
          <!-- Port -->
          <v-text-field
            label="Port mapping"
            v-model="application.portMapping"
            hint="will expose your app similar to Docker's <external port>:<container port>"
            persistent-hint
             />

          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary--text">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" :loading="loading" v-on:click.native.stop="createApplication()" class="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>

  export default {
    data () {
      return {
        dialog: false,

        application: {
          name: '',
          description: '',
          image: 'quay.io/synpse/test-app:latest',
          command: '',
          entrypoint: '',
          portMapping: '8080:8080'
        }
      }
    },

    computed: {
      loading() {
        return this.$store.state.application.loading
      },
      error() {
        return this.$store.state.application.error
      },
      createdApplication() {
        return this.$store.state.application.createdApplication
      },
      projectId() {
        return this.$route.params.project
      },
      project() {
        return this.$store.state.project.project
      },
      selectedNamespaceId() {
        return this.$route.params.namespace
      }
    },

    methods: {
      createApplication () {

        let payload = {
          name: this.application.name.toString(),
          description: this.application.description.toString(),
          type: 'container',
          scheduling: {
            type: 'AllDevices',
          },
          spec: {
            containers: [{
              image: this.application.image
            }]
          }
        }
        // Adding optional ones
        if (this.application.command.length != '') {
          payload.spec.containers[0].command = this.application.command.toString()
        }
        if (this.application.entrypoint.length != '') {
          payload.spec.containers[0].entrypoint = [this.application.entrypoint.toString()]
        }
        if (this.application.portMapping.length != '') {
          payload.spec.containers[0].ports = [this.application.portMapping]
        }

        // Setting details
        payload.projectId = this.projectId
        payload.namespaceId = this.selectedNamespaceId

        this.$store.dispatch('CreateApplication', payload).then(() => {
          if (this.error != null) {
            // got error, don't reset form, let user
            // fix the mistake :S
            return
          }
          this.$store.dispatch('Notify', `Application '${payload.name}' created`)
          // Change route here to the newly created application
          this.$router.push(
            {
              name: 'applicationDetails',
              params: { project: this.projectId, namespace: this.selectedNamespaceId, application: this.createdApplication.id }
            })
          this.resetForm()
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false

        // resetting form
        this.application.name = ''
        this.application.description = ''
        this.application.image = 'quay.io/synpse/test-app:latest'
        this.application.command = ''
        this.application.entrypoint = ''
        this.application.port = '8080'
      }
    }
  }
</script>
